import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';

@Injectable({ providedIn: 'root' })
export class ToastService {
  // Sets to keep track of active error and success messages
  private activeErrorMessages: Set<string> = new Set();
  private activeSuccessMessages: Set<string> = new Set();

  // Maps to store references to active toasts
  private errorToasts: Map<string, ToastComponent> = new Map();
  private successToasts: Map<string, ToastComponent> = new Map();

  showErrorToast(error: HttpErrorResponse) {
    const toastContent = this.getErrorMessage(error);
    const cssClass =
      'e-toast-danger e-toast-header-icon e-toast-icon e-icons e-toast-error-icon';

    // Check if this error message is already being displayed
    if (this.activeErrorMessages.has(toastContent)) {
      // Optionally, refresh the existing toast or do nothing
      return;
    }

    // Add the message to the set of active error messages
    this.activeErrorMessages.add(toastContent);

    const toast = ToastUtility.show(
      {
        content: toastContent,
        showCloseButton: true,
        width: 300,
        position: { X: 'Center', Y: 'Top' },
        cssClass: cssClass,
      },
      'Error',
    ) as ToastComponent;

    // Store the toast reference
    this.errorToasts.set(toastContent, toast);

    // When the toast is closed, remove it from the active messages and the toasts map
    toast.close = () => {
      this.activeErrorMessages.delete(toastContent);
      this.errorToasts.delete(toastContent);
    };
  }

  showSuccessfulToast(message: string) {
    const toastContent = `${translate(message)}.`;
    const cssClass =
      'e-toast-success e-toast-header-icon e-toast-icon e-icons e-toast-success-icon';

    // Check if this success message is already being displayed
    if (this.activeSuccessMessages.has(toastContent)) {
      // Optionally, refresh the existing toast or do nothing
      return;
    }

    // Add the message to the set of active success messages
    this.activeSuccessMessages.add(toastContent);

    const toast = ToastUtility.show(
      {
        content: toastContent,
        showCloseButton: true,
        width: 380,
        position: { X: 'Center', Y: 'Top' },
        cssClass: cssClass,
      },
      'Success',
    ) as ToastComponent;

    // Store the toast reference
    this.successToasts.set(toastContent, toast);

    // When the toast is closed, remove it from the active messages and the toasts map
    toast.close = () => {
      this.activeSuccessMessages.delete(toastContent);
      this.successToasts.delete(toastContent);
    };
  }

  hideAllToasts() {
    // Hide all error toasts
    this.errorToasts.forEach((toast) => {
      toast.hide();
    });
    this.errorToasts.clear();
    this.activeErrorMessages.clear();

    // Hide all success toasts
    this.successToasts.forEach((toast) => {
      toast.hide();
    });
    this.successToasts.clear();
    this.activeSuccessMessages.clear();
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400:
        return this.getValidationErrors(error);
      case 500:
        return (
          translate('An error occurred while processing your request') + '.'
        );
      case 404:
        return error.error?.title ?? error.message;
      default:
        return translate('Server is not reachable, please try again') + '.';
    }
  }

  private getValidationErrors(error: HttpErrorResponse): string {
    const validationErrors: string[] = [];

    for (const value of Object.values(error.error.errors)) {
      if (Array.isArray(value)) {
        validationErrors.push(...value);
      } else {
        validationErrors.push(value as string);
      }
    }

    if (validationErrors.length === 1) {
      return validationErrors[0];
    } else {
      return `<ul style="padding-right: 16px;">${validationErrors
        .map((error) => `<li>${error}</li>`)
        .join('')}</ul>`;
    }
  }
}
